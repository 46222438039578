import * as Yup from "yup";
import {
  Box,
  Card,
  Container,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { RHFSelect } from "../components/hook-form/rhf-select";

import LoadingButton from "@mui/lab/LoadingButton";
import FormProvider from "../components/hook-form/form-provider";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { addDays, format, isWeekend } from "date-fns"; // Import date-fns functions
import { useEffect, useState } from "react";
import TicketIcon from "../assets/ticketIcon.png";
import Polygon from "../assets/polygon.png";
import Polygon2 from "../assets/polygon2.png";
import Polygon3 from "../assets/polygon3.png";
import Polygon4 from "../assets/polygon4.png";
import Polygon5 from "../assets/polygon5.png";
import Polygon6 from "../assets/polygon6.png";
import { TIMEZONE, TIMESLOTS } from "../components/utils/time";
import { TICKET_PRICES } from "../components/utils/price";
import axios from "axios";
import { dollarsToCents } from "../components/utils/common";

const OUTLETOPTIONS = [
  { label: "Marina Square", value: "marina_square" },
  { label: "West Coast Plaza", value: "west_coast_plaza" },
];

const BOOKING_FEE = 1.5;

export default function Booking() {
  const today = new Date();
  const maxDate = addDays(today, 14);
  const [datesList, setDatesList] = useState([]);
  const [timeslotsList, setTimeslotsList] = useState([]);
  const [numTicketsList, setNumTicketsList] = useState([]);
  const [pricePerTicket, setPricePerTicket] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const defaultValues = {
    outlet: "",
    startTime: "",
    numberOfTickets: "",
    selectedDate: null,
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
  };

  const BookingSchema = Yup.object().shape({
    outlet: Yup.string().required("Outlet is required"),
    selectedDate: Yup.date().required("Date is required"),
    startTime: Yup.string().required("Timeslot is required"),
    numberOfTickets: Yup.string().required("Number of tickets is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    contactNumber: Yup.string().required("Contact number is required"),
    email: Yup.string().required("Email is required"),
  });

  const methods = useForm({
    resolver: yupResolver(BookingSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting },
  } = methods;

  const selectedOutlet = watch("outlet");
  const selectedDate = watch("selectedDate");
  const selectedStartTime = watch("startTime");
  const selectedNumTickets = watch("numberOfTickets");

  const shouldDisableDate = (date) => {
    if (datesList.length === 0) return false;
    return datesList.some((d) => {
      const blockedDate = new Date(d.date);
      return !d.is_operating && date.getDate() === blockedDate.getDate();
    });
  };

  useEffect(() => {
    const getDatesList = (outlet) => {
      const apiUrl = `https://mofunland-payment-api.onrender.com/api/v1/ticketing/${outlet}`;
      axios
        .get(apiUrl)
        .then((response) => {
          setDatesList(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    if (selectedOutlet) getDatesList(selectedOutlet);
  }, [selectedOutlet]);

  useEffect(() => {
    setValue("startTime", "");
    setValue("numberOfTickets", "");
    setPricePerTicket(0);
    setTotalPrice(0);
    if (selectedDate) {
      const filteredTimeIntervals = TIMESLOTS.filter((interval) => {
        const matchingSlot = datesList.find(
          (slot) =>
            !slot.vacancies === 0 &&
            new Date(selectedDate).toLocaleDateString(undefined, {
              TIMEZONE,
            }) ===
              new Date(slot.date).toLocaleDateString(undefined, {
                TIMEZONE,
              }) &&
            slot.timeSlot === interval.value
        );
        return !matchingSlot;
      });
      setTimeslotsList(filteredTimeIntervals);
    }
  }, [selectedDate]);

  useEffect(() => {
    setValue("numberOfTickets", "");
    setPricePerTicket(0);
    setTotalPrice(0);
    if (selectedStartTime) {
      const dateObj = datesList.find(
        (item) =>
          new Date(selectedDate).toLocaleDateString(undefined, {
            TIMEZONE,
          }) ===
            new Date(item.date).toLocaleDateString(undefined, {
              TIMEZONE,
            }) && item.timeslot === selectedStartTime
      );
      let options = [];
      if (dateObj) {
        for (let i = 1; i <= dateObj.vacancies; i++) {
          options.push({ label: i, value: i });
        }
      } else {
        for (let i = 1; i <= 20; i++) {
          options.push({ label: i, value: i });
        }
      }
      setNumTicketsList(options);
    }
  }, [selectedStartTime]);

  useEffect(() => {
    setPricePerTicket(0);
    setTotalPrice(0);
    if (selectedNumTickets) {
      let totalPrice;
      const dateItem = datesList.find((item) => {
        return (
          new Date(selectedDate).toLocaleDateString(undefined, {
            TIMEZONE,
          }) ===
          new Date(item.date).toLocaleDateString(undefined, {
            TIMEZONE,
          })
        );
      });
      if (dateItem) {
        setPricePerTicket(dollarsToCents(dateItem.price));
        totalPrice = selectedNumTickets * dollarsToCents(dateItem.price);
      } else {
        const isWeekendDay = isWeekend(new Date(selectedDate));
        setPricePerTicket(
          isWeekendDay ? TICKET_PRICES.weekend : TICKET_PRICES.weekday
        );
        totalPrice = isWeekendDay
          ? selectedNumTickets * TICKET_PRICES.weekend
          : selectedNumTickets * TICKET_PRICES.weekday;
      }
      setValue("totalPrice", totalPrice);
      setTotalPrice(totalPrice);
    }
  }, [selectedNumTickets]);

  const onSubmit = handleSubmit(async (data) => {
    const apiUrl = `https://mofunland-payment-api.onrender.com/api/v1/ticketing/payment`;
    // const apiUrl = `/booking/v1/ticket`;
    const postData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      contact_number: data.contactNumber,
      quantity: selectedNumTickets,
      date: format(selectedDate, "yyyy-MM-dd"),
      location: selectedOutlet,
      timeslot: selectedStartTime,
    };
    axios
      .post(apiUrl, postData)
      .then((response) => {
        if (response.status === 201) {
          const { paymentLink } = response.data;
          if (window.self === window.top) {
            window.location.href = paymentLink;
          } else {
            window.parent.postMessage(paymentLink, "*");
          }
        } else throw Error("Unable to complete operation");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ position: "absolute", right: 140, top: 100 }}>
        <img src={Polygon} width="76px" style={{ opacity: 0.4 }} />
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: 222 }}>
        <img src={Polygon2} width="71px" style={{ opacity: 0.4 }} />
      </Box>
      <Box sx={{ position: "absolute", right: 160, top: 687 }}>
        <img src={Polygon3} width="97px" style={{ opacity: 0.4 }} />
      </Box>
      <Box sx={{ position: "absolute", right: 0, top: 385 }}>
        <img src={Polygon4} width="133px" style={{ opacity: 0.4 }} />
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: 887 }}>
        <img src={Polygon5} width="133px" style={{ opacity: 0.4 }} />
      </Box>
      <Box sx={{ position: "absolute", left: 118, top: 555 }}>
        <img src={Polygon6} width="99px" style={{ opacity: 0.4 }} />
      </Box>
      <Container
        maxWidth="md"
        sx={{
          zIndex: 999,
          py: {
            xs: "60px",
            md: "120px",
          },
        }}
      >
        <Card elevation={16} sx={{ p: 4 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Link to={"/"}>
                <img
                  src={Logo}
                  style={{
                    width: "245px",
                    marginBottom: "10px",
                  }}
                />
              </Link>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Fredoka",
                  color: "#E40025",
                  textAlign: "center",
                }}
              >
                Play & Learn Session
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, mt: 3, width: "100%" }}>
              <Box sx={{ px: { md: 5 } }}>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Stack spacing={3}>
                    <Box sx={{ pb: 1 }}>
                      <Typography variant="h6" sx={{ ml: { md: -4 } }}>
                        Select a timeslot
                      </Typography>
                    </Box>
                    <RHFSelect name="outlet" label="Outlet">
                      {OUTLETOPTIONS.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <Controller
                        name="selectedDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            {...field}
                            label="Date"
                            format="dd/MM/yyyy"
                            shouldDisableDate={shouldDisableDate}
                            minDate={today}
                            maxDate={maxDate}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                              },
                            }}
                          />
                        )}
                      />
                      <RHFSelect name="startTime" label="Timeslot">
                        {timeslotsList.map((option) => (
                          <MenuItem key={option.label} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Stack>
                    <Stack
                      sx={{ pb: 3 }}
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                    >
                      <RHFSelect
                        name="numberOfTickets"
                        label="Number of tickets"
                      >
                        {numTicketsList.map((option) => (
                          <MenuItem key={option.label} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>

                      <RHFTextField
                        disabled
                        name="totalPrice"
                        label="Price per ticket"
                        value={
                          pricePerTicket !== 0
                            ? `$${pricePerTicket} (${
                                pricePerTicket === TICKET_PRICES.weekday
                                  ? "Off-Peak"
                                  : "Peak"
                              })`
                            : ""
                        }
                        sx={{
                          backgroundColor: "rgba(145, 158, 171, 0.08)",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={TicketIcon} width={24} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Box sx={{ pb: 1 }}>
                      <Typography variant="h6" sx={{ ml: { md: -4 } }}>
                        Fill in your particulars
                      </Typography>
                    </Box>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <RHFTextField name="firstName" label="First name" />
                      <RHFTextField name="lastName" label="Last name" />
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <RHFTextField
                        name="contactNumber"
                        label="Contact number"
                        sx={{ width: { md: "49%", xs: "100%" } }}
                      />
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <RHFTextField
                        name="email"
                        label="Email"
                        sx={{ width: { md: "49%", xs: "100%" } }}
                      />
                      <Box />
                    </Stack>
                    {totalPrice !== 0 && (
                      <>
                        <Box sx={{ pb: 1 }}>
                          <Typography variant="h6" sx={{ ml: { md: -4 } }}>
                            Booking Summary
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            border: "1px solid #E5E7EB",
                            borderRadius: 1,
                            px: { xs: 2, md: 6 },
                            py: 2.5,
                          }}
                        >
                          <Stack spacing={1.5}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ ml: { md: -4 } }}
                              >
                                Sub total
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ ml: { md: -4 } }}
                              >
                                ${totalPrice.toFixed(2)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ ml: { md: -4 } }}
                              >
                                Booking Fee ($1.50 per booking)
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ ml: { md: -4 } }}
                              >
                                ${BOOKING_FEE.toFixed(2)}
                              </Typography>
                            </Box>
                            <Divider />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6" sx={{ ml: { md: -4 } }}>
                                Total
                              </Typography>
                              <Typography variant="h6" sx={{ ml: { md: -4 } }}>
                                ${(totalPrice + BOOKING_FEE).toFixed(2)}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </>
                    )}
                    <Box sx={{ pt: 5, display: "flex", justifyContent: "end" }}>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Payment
                      </LoadingButton>
                    </Box>
                  </Stack>
                </FormProvider>
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant="subtitle2">
                Copyright © Mofunland 2024. v1.0.7
              </Typography>
            </Box>
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
