import { BrowserRouter, Route, Routes } from "react-router-dom";
import Booking from "./pages/booking";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Booking />} />
    </Routes>
  );
}

export default App;
