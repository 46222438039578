import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { createTheme } from "./theme";
import LocalizationProvider from "./providers/localizationProvider";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

const settings = {
  theme: "light",
  responsiveFontSizes: true,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LocalizationProvider>
      <ThemeProvider
        theme={createTheme({
          responsiveFontSizes: settings.responsiveFontSizes,
          mode: settings.theme,
        })}
      >
        <App />
      </ThemeProvider>
    </LocalizationProvider>
  </BrowserRouter>
);
